import { Container } from '@lmc-eu/spirit-web-react';
import React, { ReactNode } from 'react';
import classes from './HomepageContainer.module.scss';

interface Props {
  children: ReactNode;
}

const HomepageContainer = ({ children }: Props) => (
  <Container UNSAFE_className={classes.HomepageContainer}>{children}</Container>
);

export default HomepageContainer;
