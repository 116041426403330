import { NextPage } from 'next';
import Head from 'next/head';
import { GetServerSideProps } from 'next/types';
import { useContext } from 'react';
import HomepageCarousel from '@components/Homepage/HomepageCarousel/HomepageCarousel';
import HomepageContainer from '@components/Homepage/HomepageContainer/HomepageContainer';
import HomepageHero from '@components/Homepage/HomepageHero/HomepageHero';
import HomepageYouCanDo from '@components/Homepage/HomepageInfo/HomepageYouCanDo';
import HomepageInterestingProfiles from '@components/Homepage/HomepageInterestingProfiles/HomepageInterestingProfiles';
import HomepageQuestionnaireSection from '@components/Homepage/HomepageQuestionnaireSection/HomepageQuestionnaireSection';
import HomepageStatistics from '@components/Homepage/HomepageStatistics/HomepageStatistics';
import WebPageLayout from '@components/layouts/WebPageLayout';
import { stringOrError } from '@libs/stringUtils';
import { initializeApollo } from 'apollo-client';
import {
  HomepagePromotedProfilesQuery,
  HomepagePromotedProfilesQueryVariables,
  HomepageStatisticsQuery,
  HomepageStatisticsQueryVariables,
  SuitableForTilesQuery,
  SuitableForTilesQueryVariables,
} from 'graphql/generated/types/graphqlTypes';
import { HOMEPAGE_PROMOTED_PROFILES, HOMEPAGE_STATISTICS } from 'graphql/queries/homepage';
import { PROFILE_SUITABLE_CARDS } from 'graphql/queries/profile';
import { getGlobalServerSideProps } from 'helpers/globalServerSide';
import { RequestContext } from 'helpers/requestContext';

const Homepage: NextPage = () => {
  const requestContextData = useContext(RequestContext);
  const canonicalUrl = `https://${requestContextData?.hostname}/`;

  return (
    <WebPageLayout>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <main>
        <HomepageHero />
        <HomepageContainer>
          <HomepageInterestingProfiles className="mt-900 mt-tablet-1200" />
          <HomepageStatistics className="my-1300 my-tablet-1400 my-desktop-1500" />
          <HomepageYouCanDo className="mb-1400 mb-tablet-1600 mb-desktop-1800" />
          <HomepageQuestionnaireSection className="mb-1300 mb-tablet-1400 mb-desktop-1500" />
          <HomepageCarousel className="mb-1400 mb-tablet-1600 mb-desktop-1800" />
        </HomepageContainer>
      </main>
    </WebPageLayout>
  );
};

export default Homepage;

export const getServerSideProps: GetServerSideProps = async ({ locale: localeOrUndefined, req }) => {
  const locale = stringOrError(localeOrUndefined);
  const { apolloClient } = initializeApollo(req.headers.host!, locale, req.headers.cookie);

  const globalServerSideProps = await getGlobalServerSideProps(apolloClient, req, locale);

  await apolloClient.query<HomepagePromotedProfilesQuery, HomepagePromotedProfilesQueryVariables>({
    query: HOMEPAGE_PROMOTED_PROFILES,
  });

  await apolloClient.query<HomepageStatisticsQuery, HomepageStatisticsQueryVariables>({
    query: HOMEPAGE_STATISTICS,
  });

  await apolloClient.query<SuitableForTilesQuery, SuitableForTilesQueryVariables>({
    query: PROFILE_SUITABLE_CARDS,
    variables: { isForHomepage: true },
  });

  return {
    props: {
      ...globalServerSideProps,
      initialApolloState: apolloClient.cache.extract(),
    },
  };
};
