import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';
import HomepageContainer from '@components/Homepage/HomepageContainer/HomepageContainer';
import HtmlParser from '@components/utilities/HtmlParser';
import NextButtonLink from '@components/utilities/NextButtonLink/NextButtonLink';
import { useGenerateRoute, useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { getUrlWithParameters } from '@libs/urlUtils';
import classes from './HomepageHero.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const GA_SOURCE_NAME = 'rozcestnik';

const HomepageHero = ({ className, ...divProps }: Props) => {
  const { t } = useTranslation(['front']);
  const generateRoute = useGenerateRoute();
  const getLocalizedRoute = useGetLocalizedRoute();

  return (
    <div className={classNames(classes.HomepageHero, className)} {...divProps}>
      <HomepageContainer>
        <div className={classes.HomepageHero__wrapper}>
          <h1>{t('REDESINGED_INDEX_HEADER_H1_TITLE')}</h1>
          <HtmlParser htmlString={t('REDESIGNED_HOMEPAGE_HERO_SUBTITLE')} className={classes.HomepageHero__subtitle} />
          <div className={classes.HomepageHero__buttons}>
            <NextButtonLink
              href={generateRoute('front.job_description.list', { zdroj: GA_SOURCE_NAME })}
              color={'primary'}
              size={'large'}
            >
              {t('REDESIGNED_HOMEPAGE_GO_TO_JD_LISTING_BUTTON')}
            </NextButtonLink>
            <NextButtonLink
              href={getUrlWithParameters(getLocalizedRoute(Route.profileMatchingResult), { zdroj: GA_SOURCE_NAME })}
              color={'secondary'}
              size={'large'}
              rel={'nofollow'}
            >
              {t('REDESIGNED_HOMEPAGE_GO_TO_COMPANY_SEARCH_BUTTON')}
            </NextButtonLink>
          </div>
        </div>
      </HomepageContainer>
    </div>
  );
};

export default HomepageHero;
