import { useQuery } from '@apollo/client';
import { ButtonLink } from '@lmc-eu/spirit-web-react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import HomepageSuitableCard from '@components/Homepage/HomepageSuitableCard/HomepageSuitableCard';
import AutoNbsp from '@components/utilities/AutoNbsp';
import Carousel from '@components/utilities/Carousel/Carousel';
import { useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import classes from './HomepageCarousel.module.scss';
import { SuitableForTilesQuery, SuitableForTilesQueryVariables } from '../../../graphql/generated/types/graphqlTypes';
import { PROFILE_SUITABLE_CARDS } from '../../../graphql/queries/profile';
import { RequestContext } from '../../../helpers/requestContext';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const HomepageCarousel = ({ className, ...divProps }: Props) => {
  const requestContextData = useContext(RequestContext);
  const absoluteBaseUrl = `https://${requestContextData?.hostname}`;
  const getLocalizedRoute = useGetLocalizedRoute();
  const { t } = useTranslation(['homepageSuitableFor', 'suitableForExplanation']);
  const { data: suitableForData } = useQuery<SuitableForTilesQuery, SuitableForTilesQueryVariables>(
    PROFILE_SUITABLE_CARDS,
    {
      variables: {
        isForHomepage: true,
      },
    },
  );

  if (!suitableForData?.suitableForTiles) return null;

  return (
    <div className={classNames(classes.HomepageCarousel__container, className)} {...divProps}>
      <div className={classes.HomepageCarousel__upperTextWrapper}>
        <h2 className={classes.HomepageCarousel__upperTextTitle}>{t('HOMEPAGE_CAROUSEL_HEADING')}</h2>
        <p className={classes.HomepageCarousel__upperTextParagraph}>
          <AutoNbsp>{t('HOMEPAGE_CAROUSEL_TEXT')}</AutoNbsp>
        </p>
      </div>
      <Carousel>
        {suitableForData.suitableForTiles.map(
          (item, index) =>
            item && (
              <HomepageSuitableCard
                key={`SuitableCard_${index}`}
                imageUrl={`${absoluteBaseUrl}/${item.imageUrl}`}
                title={t(item.label)}
                content={t(item.label, { ns: 'suitableForExplanation' })}
                suitableProfilesCount={item.countOfResults}
                link={item.companyListLink}
              />
            ),
        )}
      </Carousel>
      <div className="text-center pt-600">
        <ButtonLink href={getLocalizedRoute(Route.companyList)}>{t('HOMEPAGE_SEARCH_MY_IDEAS')}</ButtonLink>
      </div>
    </div>
  );
};
export default HomepageCarousel;
