import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';
import HtmlParser from '@components/utilities/HtmlParser';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import { useFormatNumber } from '@hooks/useNumberFormat';
import classes from './HomepageStatistics.module.scss';
import {
  HomepageStatisticsQuery,
  HomepageStatisticsQueryVariables,
} from '../../../graphql/generated/types/graphqlTypes';
import { HOMEPAGE_STATISTICS } from '../../../graphql/queries/homepage';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const HomepageStatistics = ({ className, ...divProps }: Props) => {
  const { data } = useQuery<HomepageStatisticsQuery, HomepageStatisticsQueryVariables>(HOMEPAGE_STATISTICS);
  const { formatNumber } = useFormatNumber();
  const { t } = useTranslation(['messages']);
  const { isMobile } = useActiveBreakpoint();

  if (!data?.homepageStatistics) return null;

  return (
    <div className={classNames(classes.HomepageStatistics, className)} {...divProps}>
      <div className={classes.HomepageStatistics__gradient}></div>
      <div className={classes.HomepageStatistics__content}>
        <div className={classes.HomepageStatistics__stat}>
          <div className={classes.HomepageStatistics__statTitle}>
            {formatNumber(data.homepageStatistics.questionnairesCount)}
          </div>
          <HtmlParser
            htmlString={t('HOMEPAGE_QUESTIONNAIRES_COUNT')}
            className={classes.HomepageStatistics__statSubtitle}
          />
        </div>
        {isMobile && <hr />}
        {!isMobile && <div className={classes.HomepageStatistics__verticalSeparator}></div>}
        <div className={classes.HomepageStatistics__stat}>
          <div className={classes.HomepageStatistics__statTitle}>
            {formatNumber(data.homepageStatistics.companiesCount)}
          </div>
          <HtmlParser htmlString={t('HOMEPAGE_COMPANIES_COUNT')} className={classes.HomepageStatistics__statSubtitle} />
        </div>
        {isMobile && <hr />}
        {!isMobile && <div className={classes.HomepageStatistics__verticalSeparator}></div>}
        <div className={classes.HomepageStatistics__stat}>
          <div className={classes.HomepageStatistics__statTitle}>{formatNumber(10000)}+</div>
          <HtmlParser
            htmlString={t('HOMEPAGE_JOB_OPPORTUNITIES_COUNT')}
            className={classes.HomepageStatistics__statSubtitle}
          />
        </div>
      </div>
    </div>
  );
};

export default HomepageStatistics;
