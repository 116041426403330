import classNames from 'classnames';
import React from 'react';
import classes from './CarouselPagination.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  currentPage: number;
  pagesCount: number;
  scrollToPage: (page: number) => void;
}

const CarouselPagination = ({ scrollToPage, currentPage, pagesCount, className, ...divProps }: Props) => {
  if (!pagesCount || pagesCount === Infinity) return null;

  return (
    <div {...divProps} className={classNames(className, classes.CarouselPagination)}>
      {Array.from({ length: pagesCount }, (dot, dotIndex) => (
        <button
          key={dotIndex}
          onClick={() => scrollToPage(dotIndex + 1)}
          className={classNames(
            classes.CarouselPagination__button,
            dotIndex + 1 === currentPage ? classes.CarouselPagination__buttonActive : '',
          )}
        ></button>
      ))}
    </div>
  );
};

export default CarouselPagination;
