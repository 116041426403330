import { escapeRegExp } from 'lodash';
import { useTranslation } from 'next-i18next';
import React from 'react';
import HtmlParser from '@components/utilities/HtmlParser';

interface Props {
  children: string;
  extraWordsFollowedByNbsp?: [string];
}

const AutoNbsp = ({ extraWordsFollowedByNbsp, children }: Props) => {
  const { i18n } = useTranslation();

  let text = children;

  if (i18n.language === 'cs') {
    // See: https://prirucka.ujc.cas.cz/?id=880
    const lettersFollowedByNbsp = ['k', 's', 'v', 'z', 'o', 'u', 'a', 'i'];

    const wordsFollowedByNbsp = [...lettersFollowedByNbsp];
    if (extraWordsFollowedByNbsp) wordsFollowedByNbsp.push(...extraWordsFollowedByNbsp);

    const escapedWordsFollowedByNbsp = wordsFollowedByNbsp.map((word) => escapeRegExp(word));
    const regex = new RegExp(`(\\s)(${escapedWordsFollowedByNbsp.join('|')})(\\s+)`, 'giu');

    text = text.replace(regex, '$1$2&nbsp;');
  }

  return <HtmlParser htmlString={text} as="span" />;
};

export default AutoNbsp;
