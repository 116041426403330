import { useTranslation } from 'next-i18next';

export const useFormatNumber = () => {
  const { t, i18n } = useTranslation('messages+intl-icu');

  const formatNumber = (value: number) => {
    return Intl.NumberFormat(i18n.language).format(value);
  };

  const formatPercent = (value: number) => {
    return Intl.NumberFormat(i18n.language, { style: 'percent' }).format(value / 100);
  };

  const formatOrdinal = (value: number) => {
    return t('ORDINAL_NUMBER_FORMAT', { ns: 'messages+intl-icu', number: value, formattedNumber: formatNumber(value) });
  };

  return { formatNumber, formatPercent, formatOrdinal };
};
