import { gql } from '@apollo/client';
import { PROFILE_CARD_DATA_FRAGMENT } from '../fragments/profile';

export const HOMEPAGE_STATISTICS = gql`
  query HomepageStatistics {
    homepageStatistics {
      companiesCount
      questionnairesCount
    }
  }
`;

export const HOMEPAGE_PROMOTED_PROFILES = gql`
  query HomepagePromotedProfiles {
    homepagePromotedProfiles {
      ...ProfileCardDataFragment
    }
  }
  ${PROFILE_CARD_DATA_FRAGMENT}
`;
