import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  ProfileCardLogo,
  ProfileCardPhoto,
  ProfileCardReviewAndJobDescriptionCount,
  ProfileCardSuitableFor,
} from '@components/Profile/ProfileCard/ProfileCardComponents';
import ProfileCardContainer from '@components/Profile/ProfileCard/ProfileCardContainer';
import { ImpressionListEventType } from '@libs/Enum/ImpressionLists';
import pushGACompanyImpressionListEvent from '@libs/ga/pushGACompanyImpressionListEvent';
import { getProfileDetailUrl } from '@libs/getProfileDetailUrl';
import classes from './ProfileCard.module.scss';
import { ProfileCardData } from '../../../graphql/generated/types/graphqlTypes';

interface Props {
  profileCardData: ProfileCardData;
  impressionListName: string;
  impressionListVariant: string;
  className?: string;
  targetBlank?: boolean;
  position: number;
}

const ProfileCardWithSuitableFor = ({
  profileCardData,
  impressionListName,
  impressionListVariant,
  className,
  position,
  targetBlank = false,
}: Props) => {
  const { i18n } = useTranslation(['messages', 'profile']);

  const profileUrl = getProfileDetailUrl(i18n.language, profileCardData.profile);

  const profileCardComponentProps = {
    profileCardData,
    profileUrl,
    profileImpressionListName: impressionListName,
    profileImpressionListVariant: impressionListVariant,
  };

  return (
    <ProfileCardContainer
      profileCardData={profileCardData}
      impressionListName={impressionListName}
      impressionListVariant={impressionListVariant}
      className={className}
      position={position}
    >
      <Link
        href={profileUrl}
        onClick={() => {
          pushGACompanyImpressionListEvent(
            ImpressionListEventType.click,
            profileCardData.profile,
            impressionListName,
            impressionListVariant,
            i18n.language,
            position,
            profileUrl,
          );
        }}
        className={classes.ProfileCard__unstyledLink}
        {...(targetBlank ? { target: '_blank' } : {})}
      >
        <ProfileCardPhoto {...profileCardComponentProps} />
        <div className={classNames(classes.ProfileCard__section, 'pt-300 pb-0')}>
          <ProfileCardLogo {...profileCardComponentProps} />
          <div className={classes.ProfileCard__contentHeader}>
            <div className={classNames(classes.ProfileCard__name, 'mb-300 link')}>
              {profileCardData.profile.expandedName}
            </div>
            <ProfileCardReviewAndJobDescriptionCount {...profileCardComponentProps} />
          </div>
        </div>
        <div className={classes.ProfileCard__section}>
          <ProfileCardSuitableFor {...profileCardComponentProps} />
        </div>
      </Link>
    </ProfileCardContainer>
  );
};

export default ProfileCardWithSuitableFor;
