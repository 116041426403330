import classNames from 'classnames';
import React from 'react';
import classes from './CarouselItem.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  fullWidth?: boolean;
}

const CarouselItem = ({ fullWidth = false, className, children, ...divProps }: Props) => {
  return (
    <div
      {...divProps}
      className={classNames(className, fullWidth ? classes.CarouselItemFullWidth : classes.CarouselItem)}
    >
      {children}
    </div>
  );
};

export default CarouselItem;
