import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  ProfileCardLogo,
  ProfileCardReviewAndJobDescriptionCount,
  sendCompanyImpressionListClickEvent,
} from '@components/Profile/ProfileCard/ProfileCardComponents';
import ProfileCardContainer from '@components/Profile/ProfileCard/ProfileCardContainer';
import { getProfileDetailUrl } from '@libs/getProfileDetailUrl';
import classes from './ProfileCard.module.scss';
import { ProfileCardData } from '../../../graphql/generated/types/graphqlTypes';

interface Props {
  profileCardData: ProfileCardData;
  impressionListName: string;
  impressionListVariant: string;
  targetBlank?: boolean;
  position: number;
}

const ProfileCardMini = ({
  profileCardData,
  impressionListName,
  impressionListVariant,
  position,
  targetBlank = false,
}: Props) => {
  const { i18n } = useTranslation(['messages', 'profile']);

  const profileUrl = getProfileDetailUrl(i18n.language, profileCardData.profile);

  const profileCardComponentProps = {
    profileCardData,
    profileUrl,
    profileImpressionListName: impressionListName,
    profileImpressionListVariant: impressionListVariant,
  };

  return (
    <ProfileCardContainer
      profileCardData={profileCardData}
      impressionListName={impressionListName}
      impressionListVariant={impressionListVariant}
      position={position}
    >
      <Link
        href={profileUrl}
        onClick={() => {
          sendCompanyImpressionListClickEvent(
            profileCardData.profile,
            impressionListName,
            impressionListVariant,
            i18n.language,
            position,
            profileUrl,
          );
        }}
        className={classes.ProfileCard__unstyledLink}
        {...(targetBlank ? { target: '_blank' } : {})}
      >
        <div className={classNames(classes.ProfileCard__section, 'pt-400 pb-500')}>
          <ProfileCardLogo className="mb-400" {...profileCardComponentProps} />
          <div className="text-center">
            <ProfileCardReviewAndJobDescriptionCount {...profileCardComponentProps} />
          </div>
        </div>
      </Link>
    </ProfileCardContainer>
  );
};

export default ProfileCardMini;
